.HambergerMenu{
    position: relative;
    width: 48px;
    height: 48px;
    margin-left: 12px;
    border-radius: 50%;
    background-color: #F0DFBF;
}
.HambergerMenu > div{
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #EA5825;
}