.header{
    position: fixed;
    transform: translate(-50%,0%);
    left: 50%;
    top: 0;
    width: 100%;
    padding: 12px;
    z-index: 10;

    display: flex;
    justify-content: flex-end;
}