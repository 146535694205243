.nav{
  background-color: #F0DFBF;
  border-radius: 5px;
}
.nav ul{
  display: flex;
}
.nav li{
  text-align: center;
  margin: 8px 8px;
}
.nav li *{
  display: block;
  font-size: 12px;
  width: 120px;
  padding: 10px 0;
  background-color: #EA5825;
  color: #F0DFBF;;
  border-radius: 5px;
  text-decoration:none;
}